import React, { useState, useEffect } from "react";
import {
  useDispatch, connect, useSelector
} from "react-redux";
import {
  Row, Col, Card, CardBody, 
  CardTitle, CardHeader, 
} from "reactstrap";
import {
  Table, Thead, Tbody, Tr, Th, Td
} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// i18n
import { withTranslation } from "react-i18next";
import CustomPagination from "components/Common/CustomPagination";
import TableLoader from "components/Common/TableLoader";
// import Notification from "components/Common/Notification";
// import logo from "assets/images/logo-sm.svg";
import { captilazeFirstLetter } from "common/utils/manipulateString";
import formatDate from "helpers/formatDate";
import Badge from "components/Common/Badge";
import CustomDropDown from "components/Common/CustomDropDown";
import { fetchReferralsDeposits } from "store/client/actions";


function ReferralDeposits(props) {
  const clientId = props.clientId;
  // const [showNotication, setShowNotifaction] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const dispatch = useDispatch();
  const loadClientTransactionsdetails = (page = 1, limit = 10) => {
    dispatch(fetchReferralsDeposits({ 
      clientId,
      page,
      limit
    }));
  };
  useEffect(() => {
    loadClientTransactionsdetails(1, sizePerPage);
  }, [1, sizePerPage]);


  const columns = [
    {
      dataField: "createdAt",
      text: props.t("Date"),
      formatter: (val) => formatDate(val.createdAt)
    }, 
    {
      dataField: "customerId",
      text: props.t("Client"),
      formatter:(val)=>(val.customerId ? `${captilazeFirstLetter(val.customerId?.firstName)} ${captilazeFirstLetter(val.customerId?.lastName)}` : "")
    },
    {
      dataField: "gateway",
      text: props.t("Gateway"),
      formatter: (val) => (val.gateway == "WIRE_TRANSFER" ? "Wire" : val.gateway?.split("_").join(" ")?.toLowerCase())
    },
    {
      dataField: "currency",
      text: props.t("Currency")    
    },
    {
      dataField: "status",
      text: props.t("Status"),
      formatter: (val) => <Badge status={val.status}></Badge>,
    },
    {
      dataField: "amount",
      text: props.t("Amount"),
      formatter: (item) => (
        parseFloat(item?.amount)?.toFixed(2) || "-"
      )
    },
  ];

  return (
    <React.Fragment>
      <div className="">
        <div className="container-fluid">
          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="d-flex justify-content-between  align-items-center">
                  <CardTitle className="color-primary">
                    {props.t("Clients Deposits")}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table  table-hover "
                      >
                        <Thead className="text-center table-light" >
                          <Tr>
                            { 
                              columns.map((column, index) =>
                                <Th data-priority={index} key={index}><span className="color-primary">{column.text}</span></Th>
                              )
                            }
                          </Tr>
                        </Thead>
                        {/* if no records show "No records" otherwise show records */}
                        {
                          props.referralDepositsTotalDocs === 0 
                            ?
                            <Tbody>
                              {props.referralDepositsLoading && <TableLoader colSpan={4} />}                            
                              {!props.referralDepositsLoading && /*props.totalDocs === 0 && */
                                <>
                                  <Tr>
                                    <Td colSpan={"100%"} className="fw-bolder text-center" st="true">
                                      <h3 className="fw-bolder text-center">No records</h3>
                                    </Td>
                                  </Tr>
                                </>
                              }
                            </Tbody>
                            :
                            <Tbody style = {{
                              fontSize: "12px",
                              textAlign: "center",
                              textTransform: "capitalize"
                            }}>
                              {props.referralDepositsLoading && <TableLoader colSpan={4} />}
                              {!props.referralDepositsLoading && props.referralDeposits.map((row, rowIndex) =>
                                <Tr key={rowIndex}>
                                  {
                                    columns.map((column, index) =>
                                      <Td key={`${rowIndex}-${index}`}>
                                        { column.dataField === "checkbox" ? <input type="checkbox"/> : ""}
                                        { column.formatter ? column.formatter(row, rowIndex) : row[column.dataField]}
                                      </Td>
                                    )
                                  }
                                </Tr>
                              )}
                            </Tbody>
                        }
                      </Table>
                      <CustomPagination
                        totalPages={props.referralDepositsTotalPages}
                        totalDocs={props.referralDepositsTotalDocs}
                        page={props.referralDepositsPage}
                        hasNextPage={props.referralDepositsHasNextPage}
                        hasPrevPage={props.referralDepositsHasPrevPage}
                        limit={props.referralDepositsLimit}
                        nextPage={props.referralDepositsNextPage}
                        pagingCounter={props.referralDepositsPagingCounter}
                        prevPage={props.referralDepositsPrevPage}
                        docs={props.referralDeposits}
                        setSizePerPage={setSizePerPage}
                        sizePerPage={sizePerPage}
                        onChange={loadClientTransactionsdetails}
                      />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  referralDeposits: state.clientReducer.referralDeposits,
  referralDepositsLoading: state.clientReducer.referralDepositsLoading,
  referralDepositsTotalDocs: state.clientReducer.referralDepositsTotalDocs,
  referralDepositsPage: state.clientReducer.referralDepositsPage || 1,
  referralDepositsTotalPages: state.clientReducer.referralDepositsTotalPages || 0,
  referralDepositsHasNextPage: state.clientReducer.referralDepositsHasNextPage,
  referralDepositsHasPrevPage: state.clientReducer.referralDepositsHasPrevPage,
  referralDepositsLimit: state.clientReducer.referralDepositsLimit,
  referralDepositsNextPage: state.clientReducer.referralDepositsNextPage,
  referralDepositsPagingCounter: state.clientReducer.referralDepositsPagingCounter,
  referralDepositsPrevPage: state.clientReducer.referralDepositsPrevPage,
});

export default connect(mapStateToProps, null)(withTranslation()(ReferralDeposits));
